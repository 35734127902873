
export const AppMenus = {
    
	navbarTopRight: [],
	navbarTopLeft: [],
	navbarSideLeft: [
  {
    "to": "/home",
    "label": "Inicio",
    "icon": "pi pi-home",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/request_proposal",
    "label": "Solicitudes de cotización",
    "icon": "pi pi-credit-card",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/authtable",
    "label": "Usuarios",
    "icon": "pi pi-users",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "/clients",
    "label": "Clientes",
    "icon": "pi pi-book",
    "iconcolor": "",
    "target": "",
    
  },
  {
    "to": "",
    "label": "Proyectos",
    "icon": "pi pi-briefcase",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/projects/seguimientoadmin",
        "label": "Seguimiento administrativo",
        "icon": "pi pi-folder-open",
        "iconcolor": "",
        "target": "",
        
      },
    ]
  },
  {
    "to": "",
    "label": "Costos",
    "icon": "pi pi-dollar",
    "iconcolor": "",
    "target": "",
    "items": [
      {
        "to": "/projects/seguimiento",
        "label": "Seguimiento",
        "icon": "pi pi-forward",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/projects/costosfirmadas",
        "label": "Cotizaciones firmadas",
        "icon": "pi pi-check-square",
        "iconcolor": "",
        "target": "",
        
      },
      {
        "to": "/projects/semanasmes",
        "label": "Metas",
        "icon": "pi pi-flag",
        "iconcolor": "",
        "target": "",
        
      }
    ]
  }
],

    exportFormats: {
        print: {
			label: 'Print',
            icon: 'pi pi-print',
            type: 'print',
            ext: 'print',
        },
        pdf: {
			label: 'Pdf',
			
            icon: 'pi pi-file-pdf',
            type: 'pdf',
            ext: 'pdf',
        },
        excel: {
			label: 'Excel',
            icon: 'pi pi-file-excel',
            type: 'excel',
            ext: 'xlsx',
        },
        csv: {
			label: 'Csv',
            icon: 'pi pi-table',
            type: 'csv',
            ext: 'csv',
        },
    },
    
}