
import { createRouter, createWebHashHistory } from 'vue-router';

import { useAuth } from 'src/composables/auth';


function passRouteToProps(route){
	return {
		queryParams: route.query,
		fieldName: route.params.fieldName, 
		fieldValue: route.params.fieldValue
	}
}


let routes = [
	//Dashboard routes


//authtable routes
			{
				path: '/authtable/:fieldName?/:fieldValue?',
				name: 'authtablelist',
				component: () => import('./pages/authtable/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/authtable/view/:id', 
				name: 'authtableview', 
				component: () => import('./pages/authtable/view.vue'), 
				props: true
			},
		
			{ 
				path: '/index/register', 
				name: 'authtableuserregister', 
				component: () => import('./pages/index/userregister.vue'), 
				props: true
			},
	
			{ 
				path: '/account/edit', 
				name: 'authtableaccountedit', 
				component: () => import('./pages/account/accountedit.vue'), 
				props: true
			},
	
			{ 
				path: '/account', 
				name: 'authtableaccountview', 
				component: () => import('./pages/account/accountview.vue'), 
				props: true
			},
	
			{ 
				path: '/authtable/add', 
				name: 'authtableadd', 
				component: () => import('./pages/authtable/add.vue'), 
				props: true
			},
	
			{ 
				path: '/authtable/edit/:id', 
				name: 'authtableedit', 
				component: () => import('./pages/authtable/edit.vue'), 
				props: true
			},
		

//clients routes
			{
				path: '/clients/:fieldName?/:fieldValue?',
				name: 'clientslist',
				component: () => import('./pages/clients/list.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{ 
				path: '/clients/view/:id', 
				name: 'clientsview', 
				component: () => import('./pages/clients/view.vue'), 
				props: true
			},
		
			{ 
				path: '/clients/add', 
				name: 'clientsadd', 
				component: () => import('./pages/clients/add.vue'), 
				props: true
			},
	
			{ 
				path: '/clients/edit/:id', 
				name: 'clientsedit', 
				component: () => import('./pages/clients/edit.vue'), 
				props: true
			},
		

//months routes

//permissions routes

//projects routes
		
			{
				path: '/projects/seguimiento/:fieldName?/:fieldValue?',
				name: 'projectsseguimiento',
				component: () => import('./pages/projects/seguimiento.vue'), 
				props: route => passRouteToProps(route)
			},
	
		
			{
				path: '/projects/costosfirmadas/:fieldName?/:fieldValue?',
				name: 'projectscostosfirmadas',
				component: () => import('./pages/projects/costosfirmadas.vue'), 
				props: route => passRouteToProps(route)
			},
	
			{
				path: '/projects/semanasmes/:fieldName?/:fieldValue?',
				name: 'projectssemanasmes',
				component: () => import('./pages/projects/semanasmes.vue'), 
				props: route => passRouteToProps(route)
			},
			{
				path: '/projects/seguimientoadmin/:fieldName?/:fieldValue?',
				name: 'projectsseguimientoadmin',
				component: () => import('./pages/projects/seguimientoadmin.vue'), 
				props: route => passRouteToProps(route)
			},
		
		
//request proposal routes
{
	path: '/request_proposal/:fieldName?/:fieldValue?',
	name: 'requestprosals',
	component: () => import('./pages/requestproposal/list.vue'), 
	props: route => passRouteToProps(route)
},
{ 
	path: '/request_proposal/add', 
	name: 'request_proposaladd', 
	component: () => import('./pages/requestproposal/add.vue'), 
	props: true
},
{ 
	path: '/request_proposal/edit/:id', 
	name: 'request_proposal', 
	component: () => import('./pages/requestproposal/edit.vue'), 
	props: true
},



//status routes

//statusdep routes

//type routes

//year routes

	
	
//Password reset routes
			{ 
				path: '/index/forgotpassword', 
				name: 'forgotpassword', 
				component: () => import('./pages/index/forgotpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword', 
				name: 'resetpassword', 
				component: () => import('./pages/index/resetpassword.vue'), 
				props: true
			},
			{ 
				path: '/index/resetpassword_completed', 
				name: 'resetpassword_completed', 
				component: () => import('./pages/index/resetpassword_completed.vue'), 
				props: true
			},
	
	
	
	{ 
		path:  '/error/forbidden', 
		name: 'forbidden', 
		component: () => import('./pages/errors/forbidden.vue'),
		props: true
	},
	{ 
		path: '/error/notfound', 
		name: 'notfound',
		component: () => import('./pages/errors/pagenotfound.vue'),
		props: true
	},
	{
		path: '/:catchAll(.*)', 
		component: () => import('./pages/errors/pagenotfound.vue')
	},
	{ 
		path: '/survey', 
		name: 'surveyform', 
		component: () => import('./pages/survey/form.vue'), 
		props: true
	},

];

export default () => {
	
const auth = useAuth();

	
	const user = auth.user;
	if(user){
		routes.push({ path: '/', alias: '/home', name: 'home', component: () => import(`./pages/home/home.vue`) });
	}
	else{
		routes.push({ path: '/', alias: '/index', name: 'index', component: () => import('./pages/index/index.vue') });
	}

	const router = createRouter({
		history: createWebHashHistory(),
		routes,
		scrollBehavior(to, from, savedPostion){
			if(savedPostion) return savedPostion;
			return { top:0 }
		}
	});
	
	router.beforeEach((to, from, next) => {
		const user = auth.user;
		let path = to.path;
		let authRequired = auth.pageRequiredAuth(path);
		if (to.name === 'surveyform' && to.query.token) {
			return next();
		}

		if (authRequired) {
			if(!user){
				return next({ path: '/',  query: { nexturl: to.fullPath } });
			}
			//authorize user
			if (!auth.canView(path)) {
				return next({path: "/error/forbidden"});
			}
		}

		if(user && to.name == "index"){
			//already logged in, show home when try to access index page
			return next({ path: "/home"});
		}

		//navigate to redirect url if available
		if(to.name == "home" && to.query.nexturl){
			return next({ path: to.query.nexturl});
		}

 		//close dialog from previous page
		//store.closeDialogs('app/closeDialogs');
		
		next();
	});

	return router;
}
